import { object, date, ref, string, number, mixed } from 'yup';

export const validationSchemaForUpdatingInvoice = () =>
  object().shape({
    amountInsured: string()
      .matches(/^[₦,0-9][0-9,.]*$/, 'Please enter a valid amount')
      .required('This field is required'),
    startDate: date().required('This field is required'),
    endDate: date()
      .min(ref('startDate'), 'End date can not be before start date')
      .required('This field is required'),
    narration: string().required('This field is required'),
    commissionType: string().required('This field is required'),
    commissionRate: string()
      .matches(/^[₦,0-9][0-9,.]*$/, 'Please enter a valid commission rate')
      .required('This field is required'),
  });

export const validationSchemaForAddingPayment = () =>
  object().shape({
    amountToPay: string()
      .matches(/^[0-9,.]*$/, 'Please enter a valid amount')
      .required('This field is required'),
    dateOfActualPayment: date().required('This field is required'),
    modeOfPayment: string().required('This field is required'),
    chequeNumber: number().when('modeOfPayment', {
      is: 'Cheque' || 'Bank Transfer',
      then: number().required('This field cheque number is required'),
    }),
    bankName: string().when('modeOfPayment', {
      is: 'Cheque',
      then: string().required('This field bank name is required'),
    }),
  });

export const validationSchemaForRemittingPremium = () =>
  object().shape({
    amountToPay: string()
      .matches(/^[0-9,.]*$/, 'Please enter a valid amount')
      .required('This field is required'),
    date: date().nullable().required('Please select a date'),
    dateToRemitBalance: mixed().when('remittanceType', {
      is: 'Partial Remittance',
      then: date()
        .min(new Date(), 'Date should be the current date or later')
        .min(ref('date'), 'Date should be after remittance date')
        .nullable()
        .required('Please select a date'),
    }),
    remittanceMode: string()
      .oneOf(['Cheque', 'Bank Transfer', 'Other'])
      .required('Please select a remittance mode'),
    remittanceBankName: string().when('remittanceMode', {
      is: (value) => ['Cheque', 'Bank Transfer'].includes(value),
      then: string().required('Bank name is required'),
    }),
    remittanceChequeNumber: string().when('remittanceMode', {
      is: 'Cheque',
      then: string()
        .min(6, 'Cheque number must be at least 6 characters')
        .max(20, 'Cheque number must be at most 20 characters')
        .required('Cheque number is required'),
    }),
    otherRemitDescription: string().when('remittanceMode', {
      is: 'Other',
      then: string().required('Kindly describe your mode of remittance'),
    }),
  });

export const validationSchemaForUploadingRemittanceDocument = () =>
  object().shape({
    underwriterReceiptNumber: string()
      .min(6, 'Receipt number must be at least 6 characters')
      .max(20, 'Receipt number must be at most 20 characters')
      .required('Receipt number is required'),
  });

export const validationSchemaForRemittingVAT = () =>
  object().shape({
    amountToPay: string()
      .matches(/^[₦,0-9][0-9,.]*$/, 'Please enter a valid amount')
      .required('This field is required'),
    date: date().nullable().required('Please select a date'),
    remittanceMode: string()
      .oneOf(['Cheque', 'Bank Transfer'])
      .required('Please select a remittance mode'),
    remittanceBankName: string().when('remittanceMode', {
      is: (value) => ['Cheque', 'Bank Transfer'].includes(value),
      then: string().required('Bank name is required'),
    }),
    remittanceChequeNumber: string().when('remittanceMode', {
      is: 'Cheque',
      then: string()
        .min(6, 'Cheque number must be at least 6 characters')
        .max(20, 'Cheque number must be at most 20 characters')
        .required('Cheque number is required'),
    }),
  });

export const validationSchemaForUploadingVatDocument = () =>
  object().shape({
    vatReceiptNumber: string()
      .min(6, 'Receipt number must be at least 6 characters')
      .max(20, 'Receipt number must be at most 20 characters')
      .required('Receipt number is required'),
  });

import {
  REQUEST_ACTION,
  SEND_QUOTE_REQUEST_ACTION,
  CREATE_INSURANCE_QUOTE_SUCCESS,
  CREATE_INSURANCE_QUOTE_FAILURE,
  GET_INSURANCE_QUOTE_SUCCESS,
  GET_INSURANCE_QUOTE_FAILURE,
  GET_POLICY_HOLDER_SUCCESS,
  QUOTE_ACTION,
  GET_PROPERTY_CATEGORY_SUCCESS,
  GET_SINGLE_INSURANCE_QUOTE_SUCCESS,
  GET_SINGLE_INSURANCE_QUOTE_FAILURE,
  UPDATE_INSURANCE_QUOTE_SUCCESS,
  UPDATE_INSURANCE_QUOTE_FAILURE,
  GET_CLIENT_INSURANCE_QUOTE_SUCCESS,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  ADD_INVOICE_PAYMENT_FAILURE,
  ADD_INVOICE_PAYMENT_SUCCESS,
  SAVE_INSURANCE_QUOTE_SUCCESS,
  SAVE_INSURANCE_QUOTE_FAILURE,
  SEND_QUOTE_SUCCESS,
  GENERATE_DEBIT_NOTE,
  GENERATE_CREDIT_NOTE,
  UPDATE_QUOTE_APPROVAL_STATUS_SUCCESS,
  DOWNLOAD_PDF_NOTE_LOADING,
  DOWNLOAD_PDF_NOTE_SUCCESS,
  DOWNLOAD_PDF_NOTE_FAILURE,
  SEND_DEBIT_NOTE_LOADING,
  SEND_DEBIT_NOTE_SUCCESS,
  SEND_CREDIT_NOTE_LOADING,
  SEND_CREDIT_NOTE_SUCCESS,
  SEND_DEBIT_NOTE_FAILURE,
  SEND_CREDIT_NOTE_FAILURE,
  SEND_DEBIT_INVOICE_SUCCESS,
  GENERATE_DEBIT_INVOICE_SUCCESS,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_FAILURE,
  QUOTE_UPDATE_ACTION,
  QUOTE_UPDATE_FAIL_ACTION,
  QUOTE_FAIL_ACTION,
  GENERATE_RECEIPT_NOTE,
  SEND_RECEIPT_INVOICE_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import socket from '../../../socket';
import moment from 'moment';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const sendQuoteRequestAction = () => ({
  type: SEND_QUOTE_REQUEST_ACTION,
});

export const requestQuoteAction = () => ({
  type: QUOTE_ACTION,
});

export const requestQuoteFailAction = () => ({
  type: QUOTE_FAIL_ACTION,
});

export const requestQuoteUpdateAction = () => ({
  type: QUOTE_UPDATE_ACTION,
});

export const requestQuoteUpdateFailAction = () => ({
  type: QUOTE_UPDATE_FAIL_ACTION,
});

export const createQuote = (payload) => ({
  type: CREATE_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const createQuoteFailure = (error) => ({
  type: CREATE_INSURANCE_QUOTE_FAILURE,
  payload: error,
});

export const getQuote = (payload) => ({
  type: GET_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const getQuoteFailure = (error) => ({
  type: GET_INSURANCE_QUOTE_FAILURE,
  payload: error,
});

export const downloadFileSuccess = (payload) => ({
  type: DOWNLOAD_PDF_NOTE_SUCCESS,
  payload,
});

export const downloadFileFailure = (error) => ({
  type: DOWNLOAD_PDF_NOTE_FAILURE,
  error,
});

export const getPolicyHolder = (payload) => ({
  type: GET_POLICY_HOLDER_SUCCESS,
  payload,
});

export const getPropertyCategory = (payload) => ({
  type: GET_PROPERTY_CATEGORY_SUCCESS,
  payload,
});

export const getSingleQuote = (payload) => ({
  type: GET_SINGLE_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const getSingleQuoteFailure = () => ({
  type: GET_SINGLE_INSURANCE_QUOTE_FAILURE,
});

export const updateQuote = (payload) => ({
  type: UPDATE_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const updateQuoteFailure = (payload) => ({
  type: UPDATE_INSURANCE_QUOTE_FAILURE,
  payload,
});

export const getClientQuote = (payload) => ({
  type: GET_CLIENT_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const getInvoices = (payload) => ({
  type: GET_INVOICES_SUCCESS,
  payload,
});

export const getInvoicesFailure = () => ({
  type: GET_INVOICES_FAILURE,
});

export const addPaymentSuccess = (payload) => ({
  type: ADD_INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const addPaymentFailure = (payload) => ({
  type: ADD_INVOICE_PAYMENT_FAILURE,
  payload,
});

export const saveQuoteSuccess = (payload) => ({
  type: SAVE_INSURANCE_QUOTE_SUCCESS,
  payload,
});

export const saveQuoteFailure = (payload) => ({
  type: SAVE_INSURANCE_QUOTE_FAILURE,
  payload,
});

export const sendQuoteSuccess = (payload) => ({
  type: SEND_QUOTE_SUCCESS,
  payload,
});

export const generateDebitNoteSuccess = (payload) => ({
  type: GENERATE_DEBIT_NOTE,
  payload,
});

export const generateCreditNoteSuccess = (payload) => ({
  type: GENERATE_CREDIT_NOTE,
  payload,
});

export const generateReceiptNoteSuccess = (payload) => ({
  type: GENERATE_RECEIPT_NOTE,
  payload,
});

export const quoteApprovalSuccess = (payload) => ({
  type: UPDATE_QUOTE_APPROVAL_STATUS_SUCCESS,
  payload,
});

export const sendDebitInvoiceSuccess = (payload) => ({
  type: SEND_DEBIT_INVOICE_SUCCESS,
  payload,
});

export const sendReceiptInvoiceSuccess = (payload) => ({
  type: SEND_RECEIPT_INVOICE_SUCCESS,
  payload,
});

export const generateDebitInvoiceSuccess = (payload) => ({
  type: GENERATE_DEBIT_INVOICE_SUCCESS,
  payload,
});

export const requestQuoteSuccess = (payload) => ({
  type: REQUEST_QUOTE_SUCCESS,
  payload,
});

export const requestQuoteFailure = (error) => ({
  type: REQUEST_QUOTE_FAILURE,
  payload: error,
});

/**
 * Create a thunk which makes an API call to create insurance quote
 *
 * @param {Object} payload
 * @param {string} insuranceCategory
 * @param {integer} quoteId
 *
 * @returns {Function}
 */
export const createQuoteAction = (
  payload,
  insuranceCategory,
  quoteId,
) => async (dispatch) => {
  dispatch(requestQuoteAction());
  try {
    const quoteIdd = quoteId ? `?quoteId=${quoteId}` : '';
    const { data } = await axios.post(
      `/quotes/${insuranceCategory}${quoteIdd}`,
      payload,
    );
    dispatch(createQuote(data));

    return { success: true, data };
  } catch (error) {
    dispatch(createQuoteFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get insurance quote
 *
 * @param {integer} limit
 *
 * @returns {Function}
 */
export const getQuoteAction = (limit, page, status) => async (dispatch) => {
  dispatch(requestAction());

  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const { data } = await axios.get(`/quotes?${limit}${page}${status}`);
    const quotes = data.data.quote.map((quote) => {
      return {
        ...quote,
        createdAt: moment(quote.created_at).format('DD/MM/YYYY, h:mm:ss a'),
        updatedAt: moment(quote.updated_at).format('DD/MM/YYYY, h:mm:ss a'),
      };
    });
    dispatch(getQuote(quotes));

    return data;
  } catch (error) {
    dispatch(getQuoteFailure({ error: error?.response?.data?.error }));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get policy holder types
 *
 * @returns {Function}
 */
export const getPolicyHolderAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/policyHolderType');
    dispatch(getPolicyHolder(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get property categories
 *
 * @returns {Function}
 */
export const getPropertyCategoryAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/propertyCategory');
    dispatch(getPropertyCategory(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get a single insurance quote
 *
 * @param {integer} quoteId
 *
 * @returns {Function}
 */
export const getSingleQuoteAction = (quoteId) => async (dispatch) => {
  dispatch(requestQuoteAction());
  try {
    const { data } = await axios.get(`/quote/${quoteId}`);
    dispatch(getSingleQuote(data.data));

    return data;
  } catch (error) {
    dispatch(getSingleQuoteFailure());

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update an insurance quote
 *
 * @param {integer} quoteId
 * @param {object | string} payload
 *
 * @returns {Function}
 */
export const updateQuoteAction = (quoteId, payload) => async (dispatch) => {
  dispatch(requestQuoteUpdateAction());

  try {
    const { data } = await axios.put(`/quote/${quoteId}`, payload);
    dispatch(updateQuote(data));

    return { success: true, data };
  } catch (error) {
    dispatch(updateQuoteFailure(error));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get client insurance quote
 *
 * @param {string} email
 *
 * @returns {Function}
 */
export const getClientQuoteAction = (clientType, email) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(
      `/quotes/?clientType=${clientType}&client=${encodeURIComponent(email)}`,
    );

    dispatch(getClientQuote(data.data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getQuoteInvoicesAction = (limit, page, userId, status) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    userId = userId ? `&userId=${userId}` : '';
    status = status ? `&status=${status}` : '';
    const { data } = await axios.get(
      `/invoice/?${limit}${page}${userId}${status}`,
    );

    dispatch(getInvoices(data.data));
    return data;
  } catch (error) {
    dispatch(getInvoicesFailure());

    return errorHandler();
  }
};

export const addInvoicesPaymentAction = (payload, invoiceNo) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.put(`/invoice/${invoiceNo}`, payload);
    dispatch(addPaymentSuccess(data));

    return data;
  } catch (error) {
    dispatch(getQuoteFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to save an insurance quote
 *
 * @param {integer} quoteId
 *
 * @returns {Function}
 */
export const saveQuoteAction = (quoteId, payload) => async (dispatch) => {
  dispatch(requestQuoteAction());
  try {
    const { data } = await axios.put(`/quote/${quoteId}/save`, payload);

    socket.emit('createQuote', data.data);
    dispatch(saveQuoteSuccess(data));

    return data;
  } catch (error) {
    dispatch(saveQuoteFailure(error?.response?.error));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send an insurance quote
 *
 * @param {integer} quoteId
 * @param {string} quoteStartDate
 * @returns {Function}
 */
export const sendQuoteAction = (quoteId, quoteStartDate) => async (
  dispatch,
) => {
  dispatch(sendQuoteRequestAction());
  try {
    const { data } = await axios.get(
      `/quote/${quoteId}/notify?startDate=${quoteStartDate}`,
    );

    dispatch(sendQuoteSuccess(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to search for client
 * @param {string} clientType
 * @param {string} searchQuery
 * @returns {Function}
 */
export const searchClientAction = (clientType, searchQuery) => async () => {
  try {
    const { data } = await axios.post(`/client/search`, {
      clientType,
      searchQuery,
    });

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to generate quote invoice
 *
 * @param {integer} invoiceId
 *
 * @returns {Function}
 */
export const generateDebitNoteAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/generate/${invoiceId}/pdfs`);
    dispatch(generateDebitNoteSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const generateCreditNoteAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/generate/${invoiceId}/pdfs`);
    dispatch(generateCreditNoteSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const generateReceiptNoteAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/generate/${invoiceId}/pdfs`);
    dispatch(generateReceiptNoteSuccess(data.data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const downloadPDFNotesAction = (fileName) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_PDF_NOTE_LOADING });
  try {
    const { data } = await axios.get(`file/download?fileName=${fileName}`, {
      responseType: 'blob',
    });

    dispatch(downloadFileSuccess(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send debit invoice
 *
 * @param {integer} invoiceId
 *
 * @returns {Function}
 */
export const sendInvoiceAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const data = await axios.get(`/invoice/debitInvoice/${invoiceId}`);

    dispatch(sendDebitInvoiceSuccess(data.data));
    return { success: true, data };
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send receipt
 *
 * @param {integer} invoiceId
 *
 * @returns {Function}
 */
export const sendReceiptNoteToClientAction = (invoiceId) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const data = await axios.get(`/invoice/receiptNote/${invoiceId}`);

    dispatch(sendReceiptInvoiceSuccess(data.data));
    return { success: true, data };
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to generate debit invoice
 *
 * @param {integer} invoiceId
 *
 * @returns {Function}
 */
export const generateDebitInvoiceAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/${invoiceId}`);
    dispatch(generateDebitInvoiceSuccess(data.data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendDebitNoteToClientAction = (invoiceId) => async (dispatch) => {
  dispatch({ type: SEND_DEBIT_NOTE_LOADING });
  try {
    const { data } = await axios.get(`/invoice/send/debitNote/${invoiceId}`);
    dispatch({ type: SEND_DEBIT_NOTE_SUCCESS, payload: data });

    return { success: true, data };
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendCreditNoteToInsuranceProviderAction = (invoiceId) => async (
  dispatch,
) => {
  dispatch({ type: SEND_CREDIT_NOTE_LOADING });
  try {
    const { data } = await axios.get(`/invoice/send/creditNote/${invoiceId}`);
    dispatch({ type: SEND_CREDIT_NOTE_SUCCESS, payload: data });

    return { success: true, data };
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update quote approval status
 *
 * @param {integer} quoteId
 * @param {object} payload
 *
 * @returns {Function}
 */
export const quoteApprovalAction = (quoteId, payload) => async (dispatch) => {
  dispatch(requestQuoteUpdateAction());
  try {
    const data = await axios.patch(`/quote/${quoteId}/approve`, payload);

    socket.emit('updateQuote', data.data);
    dispatch(quoteApprovalSuccess(data.data));

    return { success: true, data };
  } catch (error) {
    dispatch(requestQuoteUpdateFailAction());

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to request for quote
 *
 * @param {object} payload
 *
 * @returns {Function}
 */
export const quoteRequestAction = (payload) => async (dispatch) => {
  dispatch(sendQuoteRequestAction());
  try {
    const { data } = await axios.post('/quote/request', payload);
    dispatch(requestQuoteSuccess(data));

    return { success: true, data };
  } catch (error) {
    dispatch(requestQuoteFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const initialState = {
  data: {},
  quoteData: {},
  receiptData: {},
  clientInsuranceQuoteData: [],
  insuranceQuoteData: [],
  policyHolderData: [],
  propertyCategoryData: [],
  invoices: [],
  isLoading: false,
  isQuoteUpdating: false,
  quoteIsLoaded: false,
  quoteLoadingFailure: false,
  downloadPdfLoading: false,
  isQuoteLoading: false,
  debitNoteData: null,
  creditNoteData: null,
  debitInvoiceData: {},
  updatedQuote: {},
  error: {},
  isSending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_QUOTE_REQUEST_ACTION:
      return {
        ...state,
        isSending: true,
      };
    case QUOTE_ACTION:
      return {
        ...state,
        isQuoteLoading: true,
      };
    case QUOTE_FAIL_ACTION:
      return {
        ...state,
        isQuoteLoading: false,
      };
    case QUOTE_UPDATE_FAIL_ACTION:
      return {
        ...state,
        isQuoteUpdating: false,
      };
    case QUOTE_UPDATE_ACTION:
      return {
        ...state,
        isQuoteUpdating: true,
      };
    case CREATE_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isQuoteLoading: false,
      };
    case CREATE_INSURANCE_QUOTE_FAILURE:
      return {
        ...state,
        isQuoteLoading: false,
      };
    case GET_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        insuranceQuoteData: action.payload,
        isLoading: false,
      };
    case GET_INSURANCE_QUOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_POLICY_HOLDER_SUCCESS:
      return {
        ...state,
        policyHolderData: action.payload,
        isLoading: false,
      };
    case GET_PROPERTY_CATEGORY_SUCCESS:
      return {
        ...state,
        propertyCategoryData: action.payload,
        isLoading: false,
      };
    case GET_SINGLE_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        quoteData: action.payload,
        quoteIsLoaded: true,
        quoteLoadingFailure: false,
        isQuoteLoading: false,
      };
    case GET_SINGLE_INSURANCE_QUOTE_FAILURE:
      return {
        ...state,
        quoteLoadingFailure: true,
      };
    case UPDATE_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        isQuoteUpdating: false,
        updatedQuote: action.payload,
      };
    case UPDATE_INSURANCE_QUOTE_FAILURE:
      return {
        ...state,
        isQuoteUpdating: false,
        error: action.payload,
      };
    case GET_CLIENT_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        clientInsuranceQuoteData: action.payload,
        isQuoteLoading: false,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        isLoading: false,
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_INVOICE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_INSURANCE_QUOTE_SUCCESS:
      return {
        ...state,
        isQuoteLoading: false,
      };
    case SAVE_INSURANCE_QUOTE_FAILURE:
      return {
        ...state,
        isQuoteLoading: false,
      };
    case SEND_QUOTE_SUCCESS:
      return {
        ...state,
        isSending: false,
      };
    case GENERATE_DEBIT_NOTE:
      return {
        ...state,
        debitNoteData: action.payload,
        isLoading: false,
      };
    case GENERATE_CREDIT_NOTE:
      return {
        ...state,
        creditNoteData: action.payload,
        isLoading: false,
      };
    case UPDATE_QUOTE_APPROVAL_STATUS_SUCCESS:
      return {
        ...state,
        isQuoteUpdating: false,
      };
    case DOWNLOAD_PDF_NOTE_SUCCESS:
      return {
        ...state,
        downloadPdfLoading: false,
      };
    case DOWNLOAD_PDF_NOTE_FAILURE:
      return {
        ...state,
        downloadPdfLoading: false,
      };
    case SEND_DEBIT_NOTE_LOADING:
      return {
        ...state,
        sendingDebitNote: true,
      };
    case SEND_CREDIT_NOTE_LOADING:
      return {
        ...state,
        sendingCreditNote: true,
      };
    case SEND_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        sendingDebitNote: false,
        data: action.payload,
      };
    case SEND_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        sendingCreditNote: false,
        data: action.payload,
      };
    case SEND_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        sendingDebitNote: false,
      };
    case SEND_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        sendingCreditNote: false,
      };
    case GENERATE_DEBIT_INVOICE_SUCCESS:
      return {
        ...state,
        debitInvoiceData: action.payload,
        isLoading: false,
      };
    case SEND_DEBIT_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_RECEIPT_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        quoteData: action.payload,
        isSending: false,
      };
    case REQUEST_QUOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isSending: false,
      };
    case GENERATE_RECEIPT_NOTE:
      return {
        ...state,
        receiptData: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderLogo from '../HeaderLogo';
import homeIcon from '../../assets/icons/home.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import quoteIcon from '../../assets/icons/quotation.svg';
// import subscriptionIcon from '../../assets/icons/invoice.svg';
import reportIcon from '../../assets/icons/report.svg';
import policyIcon from '../../assets/icons/policy.svg';
import accountIcon from '../../assets/icons/account.svg';
import Accordion from '../Accordion';
import { decodedToken } from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

const sideBarLinks = [
  {
    title: 'Home',
    link: '/dashboard',
    linkLogo: homeIcon,
    linkAlt: 'Home',
  },
  // {
  //   title: 'Subscription',
  //   link: '/subscription',
  //   linkLogo: subscriptionIcon,
  //   linkAlt: 'Subscription',
  // },
];

const policyLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/policies"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Policy Proposal
    </NavLink>
    {/* <Nav
      Link
      to="/policy/new"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Create Policy
    </NavLink> */}
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/policy/endorsement"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Policy Endorsement
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/policies/allRefund"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Refunded Premium
    </NavLink>
  </div>
);

const quoteLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/quotes"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Create Quote
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/request-quote"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Request Quote
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/list/quotes"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Quotes
    </NavLink>
  </div>
);

const clientLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/client-setup"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Create Client
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/all-clients"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      View Clients
    </NavLink>
  </div>
);

const reportLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/expiring-policies"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Expiring Policies
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/cash-flow"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Cash Flow
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/summary"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Summary of Accounts
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/balance-sheet"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Balance Sheet
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/trialBalance"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Trial Balance
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/incomeStatement"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Income Statement
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/reports/regulatory-report"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Regulatory Reports
    </NavLink>
  </div>
);
const accountManagementLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/accountmanagement/view-plans"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      View Plans
    </NavLink>
    {/* <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/accountmanagement/subscription"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Subscription
    </NavLink> */}
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/accountmanagement/license"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      License
    </NavLink>
  </div>
);
const adminLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/users"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Users
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/roles"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Roles
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/groups"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Groups
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/permissions"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Permissions
    </NavLink>
  </div>
);

const systemLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/products"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Products
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/underwriters"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Underwriter
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/settings"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Profile
    </NavLink>
    <NavLink
      to="/email-integration"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Email Integration
    </NavLink>
    <NavLink
      to="/signature-integration"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Signature Integration
    </NavLink>
  </div>
);

const accountLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/account/general-ledger"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Chart of Accounts
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/account/transactions"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Transactions
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/account/accounting-periods"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Accounting Period
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/account/invoices"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Invoices
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/account/multi-currency"
      className="hidden py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Manage Currency
    </NavLink>
  </div>
);

const claimsLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/claims/list"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Claims
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/claims/create"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Register claims
    </NavLink>
    {/* <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/claims/settedByBroker"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Settled By Broker
    </NavLink> */}
  </div>
);

const remittanceManagementLinks = (isOpen, setIsOpen) => (
  <div>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/remit-premium"
      // to="/account/invoices"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Create Schedule
    </NavLink>
    <NavLink
      onClick={() => setIsOpen(!isOpen)}
      to="/premium-remitted"
      className="flex py-2 pl-4 ml-8 text-xs outline-none cursor-pointer"
      activeClassName="font-bold"
    >
      Remittance Schedules
    </NavLink>
  </div>
);

const manageUsersIcon = (
  <span className="mr-4">
    <svg width="18" height="18" viewBox="0 0 20 20" fill="white">
      <path d="M18.361 15.547s-.226.195-2.983.286c.07-.349.06-.716-.004-1.068l1.698.004c.87-.304.659-1.788 0-2.082-.474-.21-2.424-.729-3.576-1.027l-.153-.504v-.74c.96-.755 1.53-2.317 1.53-3.745 0-1.968-.462-3.564-2.773-3.564-.17 0-.31.026-.461.043-.473-.306-1.084-.497-1.856-.555C10.351 2.228 11.09 2 12.1 2c3.182 0 3.889 2.077 3.889 4.637 0 1.648-.646 3.379-1.725 4.412 1.145.283 3.4.87 3.73 1.17.734.664 1.101 2.329.368 3.328zm-6.814-7.542c0 1.763-.665 3.613-1.772 4.697 1.13.28 3.444.878 3.778 1.181.734.666 1.1 2.33.367 3.329 0 0-.367.333-6.232.333s-6.286-.333-6.286-.333c-.739-.999-.367-2.663.372-3.329.345-.31 2.625-.892 3.779-1.172C4.44 11.628 3.77 9.773 3.77 8.005c0-2.703.707-4.894 3.889-4.894 3.181 0 3.888 2.191 3.888 4.894zM7.659 4.228c-2.312 0-2.774 1.708-2.774 3.816 0 1.53.57 3.202 1.53 4.012v.766l-.135.446c-.98.285-2.93.86-3.432 1.085-.663.294-1.166 1.73-.174 2.08l4.917-.011 5.04.012c.87-.304.66-1.787 0-2.081-.474-.212-2.424-.73-3.576-1.027l-.153-.504v-.765c.96-.81 1.53-2.483 1.53-4.013 0-2.108-.462-3.816-2.773-3.816z"></path>
    </svg>
  </span>
);
const systemSetupIcon = (
  <span className="mr-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.608"
      height="20.608"
      viewBox="0 0 20.608 20.608"
      fill="white"
    >
      <path d="M18.947,0H1.261A1.263,1.263,0,0,0,0,1.261V14.905a1.263,1.263,0,0,0,1.261,1.261H5.872a9.9,9.9,0,0,0,.889.891c.562.516,1.2,1.1,1.136,1.511-.042.268-.412.576-1.042.868a.4.4,0,0,0,.17.771h6.16a.4.4,0,0,0,.17-.771c-.63-.292-1-.6-1.042-.868-.064-.411.573-1,1.136-1.511a9.922,9.922,0,0,0,.889-.891h4.611a1.263,1.263,0,0,0,1.261-1.261V1.261A1.263,1.263,0,0,0,18.947,0ZM1.261.808H18.947a.453.453,0,0,1,.453.453V12.428H.808V1.261A.453.453,0,0,1,1.261.808ZM12.9,16.462c-.747.685-1.519,1.393-1.388,2.232a1.357,1.357,0,0,0,.356.706H8.339a1.358,1.358,0,0,0,.356-.706c.131-.839-.642-1.547-1.388-2.232-.107-.1-.213-.2-.318-.3h6.228C13.114,16.265,13.007,16.364,12.9,16.462Zm6.046-1.1H1.261a.453.453,0,0,1-.453-.453V13.236H19.4v1.669A.453.453,0,0,1,18.947,15.358Zm0,0" />
      <path
        d="M133.636,52a.4.4,0,0,0,.353,0,7.517,7.517,0,0,0,4.206-6.721v-.516a.4.4,0,0,0-.4-.4c-1.955,0-2.348-.53-2.348-1.386a.4.4,0,0,0-.4-.4h-2.453a.4.4,0,0,0-.4.4c0,.856-.393,1.386-2.348,1.386a.4.4,0,0,0-.4.4v.516A7.518,7.518,0,0,0,133.636,52Zm-3.4-6.838c.977-.027,2.5-.234,2.724-1.786h1.7c.223,1.552,1.747,1.759,2.724,1.786v.117a6.706,6.706,0,0,1-3.574,5.9,6.706,6.706,0,0,1-3.574-5.9Zm0,0"
        transform="translate(-123.708 -40.685)"
      />
      <path
        d="M179.086,116.315a.4.4,0,0,0,.286-.118l2.43-2.43a.4.4,0,0,0-.572-.572l-2.145,2.145-1-1a.4.4,0,1,0-.572.572L178.8,116.2A.4.4,0,0,0,179.086,116.315Zm0,0"
        transform="translate(-169.555 -108.078)"
      />
    </svg>
  </span>
);
const collapsibleLinks = (isBroker, isAdmin) => [
  isAdmin
    ? {
        title: 'Subscription Mgmt. ',
        icon: (
          <FontAwesomeIcon className="mr-4" size="lg" icon={faFileInvoice} />
        ),
        accordionName: 'subscriptionMgt',
        renderContent: accountManagementLinks,
      }
    : null,
  {
    title: 'Manage Quote',
    icon: <img className="w-4 mr-4" src={quoteIcon} alt="" />,
    accordionName: 'quoteLink',
    renderContent: quoteLinks,
  },
  {
    title: 'Manage Client',
    icon: <img className="w-4 mr-4" src={settingsIcon} alt="" />,
    accordionName: 'clientLinks',
    renderContent: clientLinks,
  },
  {
    title: 'Policies',
    icon: <img className="w-4 mr-4" src={reportIcon} alt="" />,
    accordionName: 'policies',
    renderContent: policyLinks,
  },
  {
    title: 'Claims',
    icon: <FontAwesomeIcon className="mr-4" icon={faFileAlt} size="lg" />,
    accordionName: 'claims',
    renderContent: claimsLinks,
  },
  {
    title: 'Reports',
    icon: <img className="w-4 mr-4" src={policyIcon} alt="" />,
    accordionName: 'reports',
    renderContent: reportLinks,
  },

  isBroker
    ? {
        title: 'Accounts',
        icon: <img className="w-4 mr-4" src={accountIcon} alt="Account" />,
        accordionName: 'accounts',
        renderContent: accountLinks,
      }
    : null,
  isBroker && isAdmin
    ? {
        title: 'Remittance Mgmt',
        icon: <img className="w-4 mr-4" src={accountIcon} alt="" />,
        accordionName: 'remittanceMgmt',
        renderContent: remittanceManagementLinks,
      }
    : null,
  isAdmin
    ? {
        title: 'Manage Users',
        icon: manageUsersIcon,
        accordionName: 'manage users',
        renderContent: adminLinks,
      }
    : null,
  isAdmin
    ? {
        title: 'System Setup',
        icon: systemSetupIcon,
        accordionName: 'system setup',
        renderContent: systemLinks,
      }
    : null,
];

const SideBar = ({ logOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBroker, setIsBroker] = useState(false);
  // const [, setIsOpen] = useState(false);

  const listOfAccordionNames = [];

  useEffect(() => {
    const token = localStorage.getItem('ibmsToken');
    const tokenData = token ? decodedToken(token) : null;
    const checkAdmin =
      tokenData && tokenData.roles.find((role) => role.name === 'admin');
    const checkBroker = tokenData && tokenData.userType === 'broker';

    if (checkAdmin) setIsAdmin(true);
    if (checkBroker) setIsBroker(true);
  }, []);

  return (
    <div className="relative z-40 block overflow-visible bg-blue-750 md:h-screen md:overflow-auto md:fixed md:w-1/4 lg:w-1/5">
      <div className="flex items-center justify-between pr-3 md:pr-0 md:justify-center">
        <div className="pt-8">
          <HeaderLogo logoName="appLogo" redirect={false} />
        </div>
        <div
          className="block md:hidden lg:hidden xl:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <button className="flex items-center px-3 py-2 text-teal-200 border border-teal-400 rounded hover:text-white hover:border-white focus:outline-none">
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
      </div>
      <div
        className={
          isOpen
            ? 'text-white text-sm mt-0 xl:mt-14 lg:mt-14 md:mt-14 font-normal bg-blue-750 absolute right-0 left-0'
            : 'text-white text-sm mt-0 xl:mt-14 lg:mt-14 md:mt-14 font-normal hidden md:block'
        }
      >
        <nav className="pl-4">
          {sideBarLinks.map((sideBarLink) =>
            sideBarLink.title === 'Subscription' && isBroker ? null : (
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                key={sideBarLink.title}
                to={sideBarLink.link}
                className="flex py-2 pl-4 mb-2 font-bold rounded-tl-lg outline-none hover:bg-blue-950"
                activeClassName="bg-blue-900 rounded-tl-md font-bold"
              >
                <img
                  className="w-4 mr-4"
                  src={sideBarLink.linkLogo}
                  alt={sideBarLink.linkAlt}
                />
                {sideBarLink.title}
              </NavLink>
            ),
          )}
          {collapsibleLinks(isBroker, isAdmin).map((item, index) => {
            item && listOfAccordionNames.push(item.accordionName);
            return (
              item && (
                <Accordion
                  key={`index${index}`}
                  listOfAccordionNames={listOfAccordionNames}
                  index={index}
                  title={
                    <div className="flex pl-4 font-bold outline-none">
                      {item.icon}
                      {item.title}
                    </div>
                  }
                  titleClass="py-2 hover:bg-blue-950 rounded-tl-lg"
                  renderContent={item.renderContent(isOpen, setIsOpen)}
                  accordionName={item.accordionName}
                />
              )
            );
          })}
          <p
            className="py-2 pl-4 mb-4 font-bold rounded-tl-lg outline-none cursor-pointer xl:hidden lg:hidden md:hidden hover:bg-blue-950"
            onClick={logOut}
          >
            Logout
          </p>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;

import { toast } from 'react-toastify';

export const appendFormData = (formData, key, value) => {
  if (value) {
    formData.append(key, value);
  }
};

export const checkFiles = (files) => {
  const error = [];

  if (files) {
    const regexPattern1 = /[!@#$%^&*()+=[\]{};':"\\|,<>/?]+/;
    const regexPattern2 = /\W{2}/g;
    const fileName = files[0].name;
    const filesize = files[0].size;

    if (filesize > 2000000) {
      error.push('Check file! File size can not be more than 2MB');
    }

    if (fileName.length > 60) {
      error.push('file name should not be more than 60 characters');
    }

    if (regexPattern1.test(fileName) || fileName.match(regexPattern2)) {
      error.push('file name should not contain special characters');
    }
  }

  return error.length ? error : null;
};

export const getFileSize = (file) => {
  const fileSize = file.size;
  const fileSizeInMB = fileSize / 1000000;
  const fileSizeInGB = fileSize / 1000000000;
  const fileSizeInKB = fileSize / 1000;

  if (fileSizeInMB > 1) {
    return `${fileSizeInMB.toFixed(2)} Mb`;
  }

  if (fileSizeInGB > 1) {
    return `${fileSizeInGB.toFixed(2)} Gb`;
  }

  if (fileSizeInKB > 1) {
    return `${fileSizeInKB.toFixed(2)} Kb`;
  }

  return `${fileSize} Bytes`;
};

export const errorHandler = (error) => {
  if (error.response) {
    return error.response.data;
  } else if (error.request) {
    toast.error('Network error, please try again');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error', error.message);
  }
};
